import React,{useState} from 'react'
import CommonPrivacy from './commonPrivacy'
import WebfmPrivacy from './webfmPrivacy'
import IdlePrivacy from './idlePrivacy'
import config from '../../utils/config'
const Page = (props) => {
    return (
       <div>
         {config.appName == 'Idle Investor' ? <IdlePrivacy /> : config.appName === 'Webfm' ? <WebfmPrivacy /> : <CommonPrivacy />}
       </div>
    )
}
export default Page


