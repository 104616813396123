import React,{useRef,useEffect,useState} from 'react'
import classNames from 'classnames'
import FooterCom from '@/components/Footer'
import config from '../utils/config'
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import HeaderCom from '@/components/Header'
const Index=()=> {
    const bannerRef=useRef();
    const [downloadList,setDownloadList]=useState([]);


    useEffect(()=>{
        if(bannerRef.current){
            var swiper = new Swiper(bannerRef.current, {
                // loop: true,
                speed: 7000,
                effect: 'fade',
                autoplay: {
                    // autoplay:true,
                    // delay: 100,
                    // disableOnInteraction: true,
                    // waitForTransition: false,
                },
            });
        }

        let arr=[];
        let apple= {imgSrc:require('@/assets/images/appStore.png').default,url:config.appStoreUrl};
        let android= {imgSrc:require('@/assets/images/googlePlay.png').default,url:config.googlePlayUrl};
        if(config.googlePlayUrl){
            arr.push(android)
        }
        if(config.appStoreUrl){
            arr.push(apple)
        }
        setDownloadList(arr)
    },[])
    return (
        <div className="min-winHeight relative home">
            {/*<HeaderCom />*/}
            {/*<div className="swiper swiper-container swiper-container-fade" ref={bannerRef}>*/}
            {/*    <div className="swiper-wrapper">*/}
            {/*        {*/}
            {/*            bannerList.map((banner,index)=>{*/}
            {/*                return <div className="mv__item swiper-slide"  key={index}>*/}
            {/*                    /!*<img src={banner} />*!/*/}
            {/*                    <div className="img" style={{backgroundImage:`url(${banner})`,backgroundSize:'cover',backgroundPosition:'center center'}}></div>*/}
            {/*                </div>*/}
            {/*            })*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="swiper swiper-container"  style={{backgroundImage:`url(https://wondoo.io/static/img/wd_hp_bg1.jpg)`,backgroundSize:'cover',backgroundPosition:'center center'}}></div>
            <div className="main">
                <div className="tac">
                    <img className="logo-icon w140" src={require('@/assets/images/app_logo.png').default} alt=""/>
                    <div className="text-white fz100 lh128 ffrb">{config.appName}</div>
                    <div className="desc fz30 lh52  text-white ffrg opa5">
                        Over 500,000 Downloads
                    </div>
                    <div className="download">
                        {
                            downloadList.map((item,index)=>{
                                return <div onClick={()=>window.open(item.url)} className="download-item" key={index}>
                                    <img src={item.imgSrc} alt="" />
                                </div>
                            })
                        }
                    </div>
                </div>
                <FooterCom className="static"  />
            </div>
        </div>
    )
}

export default Index
