import React,{useState} from 'react'
import { Modal,Form, Input,message } from 'antd';
import config from "../../utils/config";
const Page = (props) => {
    let logoName=config.logoName || 'logo'

    const [form] = Form.useForm();
    const [isModalVisible,setModalVisible]=useState(false);
    const handleOk=()=>{
        form.validateFields().then(value=>{
            setModalVisible(false);
            message.success('Submitted successfully,we will process your request later ', 10)
        }).catch(err=>{
            console.log(err,'err')
        })

    }
    const handleCancel=()=>{
        setModalVisible(false)
    }

    return (
        <div className="page pad-lg pt40 pb60">
            <div className="title fz50 flex items-center ffrg">Privacy Policy</div>
                <div className="wrap fz18 lh30 mt40">
                    <div className="markdown-style"><h2 className="bold fz24" id="1-an-overview-on-data-protection">1. AN OVERVIEW ON DATA PROTECTION</h2>
                        <hr />
                            <h3 className="fw400 fz20" id="11-general-information">1.1&nbsp;General information</h3>
                            <p>The following information will provide you with an easy to navigate overview of what will happen with your personal data when you play our games. The term "personal data" comprises all data that can be used to personally identify you. For detailed information about the
                                subject matter of data protection, please consult our Privacy Policy below. </p>
                            <h3 className="fw400 fz20" id="12-data-recording">1.2 Data Recording</h3>
                            <p>How do we collect your data?
                                On the one hand, your data is collected by you communicating it to us. This may be data that you enter in a contact form, for example. Other data is automatically collected when you download and use our games.
                                &nbsp;
                                What are the purposes we use your data for?
                                We use this data to provide our games to you, including for the purposes of optimization, preventing fraud, customizing in-game offers and verifying purchases. We may also use the data for the other purposes set out in this Privacy Policy, including for Marketing and
                                Advertising purposes.
                                &nbsp;
                                What rights do you have as far as your information is concerned?
                                You have the right to receive information about the source, recipients and purposes of your archived personal data at any time without having to pay a fee for such disclosures. You also have the right to demand that your data are rectified or eradicated. Please do not
                                hesitate to contact us at any time under the address disclosed on the imprint of this website if you have questions about this or any other data protection related issues. You also have the right to log a complaint with the competent supervising agency.
                                Moreover, under certain circumstances, you have the right to demand the restriction of the processing of your personal data. For details, please consult the Privacy Policy under section Right to Restriction of Data Processing“.
                                &nbsp;
                                Analysis tools and tools provided by third parties
                                If you download and use our games, your usage data may be statistically analyzed.
                                Such analyses are performed primarily with what we refer to as analysis programs. The analyses of your usage data are usually conducted anonymously; i.e. the patterns cannot be traced back to you.
                                For detailed information about the tools and about your options to object, please consult our Privacy Policy below</p>
                            <h2 className="bold fz24" id="2-general-and-mandatory-information">2. GENERAL AND MANDATORY INFORMATION</h2>
                            <hr />
                                <h3 className="fw400 fz20" id="21-data-protection">2.1. Data Protection</h3>
                                <p>We take the protection of your personal data very seriously. Hence, we handle your personal data as confidential information and in compliance with the statutory data protection regulations and this Privacy Policy.
                                    Whenever you use our games, various personal data will be collected. According to Art. 4 No. 1 DSGVO, personal data encompasses all information relating to an identified or identifiable natural person, which includes, for example, names or identification numbers.
                                    This Privacy Policy explains which data we collect as well as the purposes we use this data for. It also explains how, and for which purpose the information is collected.
                                    We herewith advise you that the transmission of data via the Internet (i.e. through e-mail communications) may be prone to security gaps. It is not possible to completely protect data against third party access.
                                    &nbsp;</p>
                                <h3 className="fw400 fz20" id="22-information-about-the-responsible-party">2.2. Information about the responsible party</h3>
                                <p>The responsible party (referred to as the "controller" in the GDPR) is the natural person or legal entity that single handedly or jointly with others makes decisions as to the purposes of and resources for the processing of personal data (e.g. names, e-mail
                                    addresses, etc.).
                                    The controller according to data protection laws is:
                                    &nbsp;
                                    HK WONDOO CO., LIMITED
                                    &nbsp;
                                    If you have any questions or suggestions about data protection, you can send us an email to&nbsp;<a href="mailto:contact@wondoo.io">contact@wondoo.io</a>
                                    Our data protection officer can be reached at the following address:
                                    RM 2914C, 29/F, HO KING COMMERCIAL CENTER, 2-16 FA YUEN STREET, MONGKOK, KOWLOON, HONG KONG,&nbsp;</p>
                                <h2 className="bold fz24" id="3-collection-and-use-of-your-data">3. COLLECTION AND USE OF YOUR DATA</h2>
                                <hr />
                                    <h3 className="fw400 fz20" id="31-facebook-highscore">3.1.&nbsp;Facebook Highscore</h3>
                                    <p>You do not need to register in order to play our games. You can however voluntarily connect your game with your Facebook account in order to participate in the Facebook Highscore.
                                        If you connect your Facebook account with our game, the following data is shared with us:</p>
                                    <ul>
                                        <li><p>Name</p>
                                        </li>
                                        <li><p>Profile picture</p>
                                        </li>
                                        <li><p>Date of birth (if indicated by Facebook)</p>
                                        </li>
                                        <li><p>Place of residence (if indicated by Facebook)</p>
                                        </li>
                                    </ul>
                                    <p>Please note:&nbsp;If you participate in the Facebook Highscore, your friends can also see that you play our game and you will also be displayed in the Highscore of your friends. In addition, when your Facebook account is connected, a list of your friends who
                                        also participate in Facebook Highscore and who have connected their Facebook account with their game is shared.
                                        You can cancel the connection with your Facebook account at any time by logging into Facebook and selecting the "Settings" option. The connection between Facebook and the app can be deleted under "Apps and Websites."
                                        We collect this data in order to provide our app to you and to be able to connect your account if desired, Article&nbsp;6(1)(b) GDPR.
                                        To the extent that we, as described above, process your data for the purposes of providing the functions of our app, you are contractually required to provide us with this data. Without this data, we are not able to provide these functions to you.
                                        You are not required to provide the above voluntary information. Without this data, however, we are not able to provide these functions of our app to you.</p>
                                    <h3 className="fw400 fz20" id="32-support-requests">3.2.&nbsp;Support requests</h3>
                                    <p>If you send support requests to us by email, via chat within the game or via messages within social media platforms, we collect and process the data from your request in order to be able to provide a response. For this purpose, it may also be necessary for you
                                        to provide us with information about your end device so that we can process the request and resolve any technical problems that may exist.
                                        We collect this data in order to provide our app to you and to be able to process your request, Article&nbsp;6(1)(b) GDPR.
                                        You are not required to provide the above voluntary information. In particular, you are free to send the request to us under a pseudonym, without giving your real name.</p>
                                    <h2 className="bold fz24" id="4-permissions">4. PERMISSIONS</h2>
                                    <hr />
                                        <p>Our app uses the following permissions:</p>
                                        <table>
                                            <thead>
                                            <tr>
                                                <th align="center">Permission</th>
                                                <th align="left">Purpose</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td align="center">Autostart</td>
                                                <td align="left">Necessary for sending notifications to the user</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Network connection display</td>
                                                <td align="left">Receives information regarding the existence of and connection to networks.</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Google Play billing service (Android)</td>
                                                <td align="left">Allows the user to purchase in-game products.</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Full access to the network</td>
                                                <td align="left">Necessary for sending data to the Internet.</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Location</td>
                                                <td align="left">Necessary to provide contextually relevant and/or personalized customer experiences</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Search &amp; Siri suggestions (iOS)</td>
                                                <td align="left">If this function is enabled, information from the game can be displayed when searching, looking things up, and when keyboards are displayed, and Siri can learn from use of the app and make suggestions.</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Notifications (symbols, tones, banners, control of vibration)</td>
                                                <td align="left">If notifications are enabled in the settings, these can be sent to the player in order to display important events over the course of the game.</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Background updates</td>
                                                <td align="left">If this function is enabled, background updates can take place.</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Mobile data</td>
                                                <td align="left">The player can allow access to mobile data so that it can use all the functions of the game without being connected to Wi-Fi.</td>
                                            </tr>
                                            <tr>
                                                <td align="center">Access to local storage</td>
                                                <td align="left">The app characterizes the local storage and accesses it in order to be able to execute the services.</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h2 className="bold fz24" id="5-pseudonymous-user-profile">5. PSEUDONYMOUS USER PROFILE</h2>
                                        <hr />
                                            <p>For the needs-oriented design and statistical analysis of usage of our app, we collect pseudonymous data about how our apps are used, and we evaluate this data statistically. Specifically, we use third-party analytical tools in connection with our own
                                                Services. These partners process your data only at and according to our specific instructions and may use cookies and other tracking technologies to collect information about your use of our Apps. This collected information includes, but is not limited
                                                to installation data, in-app events, uninstallation, A/B test buckets, language, platform, device type, operating system version, app version, screen resolution, date and time, device IDs such as advertising IDs and IDFA and usage information. This
                                                information may be used by us and others to, among other things, analyze and track data, determine the popularity of certain content, deliver advertising and content targeted to your interests and better understand your activity on our Services.
                                                We collect this data on the basis of a legitimate interest in needs-oriented design, for statistical evaluation, efficient marketing of our app, and the fact that your legitimate interests do not prevail under Article&nbsp;6(1)(f) GDPR.</p>
                                            <h2 className="bold fz24" id="6-advertising-networks">6. ADVERTISING NETWORKS</h2>
                                            <hr />
                                                <p>We use the following advertising networks in order to be able to integrate advertising within our free-of-charge apps. These collect the data described below for the purpose of providing personalized advertising:</p>
                                                <table>
                                                    <thead>
                                                    <tr>
                                                        <th align="center">Advertising network</th>
                                                        <th align="left">Provider</th>
                                                        <th align="left">Type of data</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td align="center">AppLovin</td>
                                                        <td align="left">AppLovin Corporation, 849 High Street, Palo Alto, CA 94301, USA</td>
                                                        <td align="left">Device make, model and operating system; Device properties related to screen size &amp; orientation, audio volume and battery, device memory usage; Carrier; Operating system; Country, time zone and locale settings (country and
                                                            preferred language); Network connection type and speed; IP Address; Internet browser user-agent used to access the Services; and Identifier for advertisers (IDFA). (Privacy policy)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center">AdMob/Google</td>
                                                        <td align="left">Google Corporation, 1600 Amphitheatre Parkway in Mountain View, California, United States</td>
                                                        <td align="left">Online identifiers, including cookie identifiers, internet protocol addresses and device identifiers; client identifiers (Privacy Policy)</td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center">AdColony</td>
                                                        <td align="left">AdColony Inc. 1888 Century Park East, Suite 1450, Century City, CA 90067</td>
                                                        <td align="left">IP address, device advertising identifiers, the type of phone or other mobile device used, the operating system, the date and time a particular ad is served, whether one of the ads facilitated by them was delivered and, if
                                                            applicable, a record if that ad was “clicked” or if it originated from a follow-up action to a third party such as a "conversion" event, combined with personal data to help AdColony optimize and/or refrain from advertising for a mobile
                                                            application that has already been downloaded onto a device. (Privacy policy)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center">Chartboost</td>
                                                        <td align="left">Chartboost Inc., 85 Second Street Suite 100, San Francisco, CA 94105, USA</td>
                                                        <td align="left">Language, operating system, device type, IP address, MAC address, device ID, IDFA, Google Advertising ID, Android ID, statistics on video ads that have been viewed, length of use of an app, data regarding in-app purchases
                                                            (Privacy policy)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center">Unity Ads</td>
                                                        <td align="left">Unity Technologies ApS, Vendersgade 28, Sal 1, Kobenhavn, 1363, Denmark</td>
                                                        <td align="left">Device information, IP addresses, device identifiers, combined with Unity Analytics to personalize advertising. (Privacy policy)</td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center">IronSource</td>
                                                        <td align="left">IronSource Ltd., 28 Lilienblum, Tel Aviv, 6513307, Israel</td>
                                                        <td align="left">Device type (manufacturer and model), operating system, IP address, Android Identifier, and/or IDFA, time zone, language settings, type of internet connection, geographic information on the device, type of advertising, content
                                                            of advertising, post-click information related to advertising. (Privacy policy)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center">TikTok/Bytedance</td>
                                                        <td align="left">Beijing Bytedance Technology Co., Ltd. No. A48, Zhichun Road, Haidian District Beijing, Beijing, 100000&nbsp;China</td>
                                                        <td align="left">IP address, device information including, but not limited to, identifier, name, and type of operating system, information obtained from Cookies (as defined below) and other tracking technologies, time zone and locale setting,
                                                            and server logs (which can include information like access dates and times). (Privacy policy)
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center">Tapjoy</td>
                                                        <td align="left">Tapjoy Inc., 353 Sacramento Street, San Francisco CA 94111, USA</td>
                                                        <td align="left">Device identifiers, information about the device itself, information about the internet connection used to access our services, information about the app and the SDK, location-related information, ad-related information, survey
                                                            information, information from advertisers or publishers. (Privacy policy)
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p>| Vungle | Vungle Inc., 185 Clara Street San Francisco, CA&nbsp;94107, USA | IP address, Android ID, MAC address, IFA, UDID, activity and page visits, language, device information, time zone and city, geographic information, operating system,
                                                    information about installed apps, and already viewed advertising. (Privacy policy) |</p>
                                                <p>| Facebook Ads | Facebook Inc., 1601 Willow Road, Menlo Park, CA 94025, USA | Device attributes (operating system, hardware, software versions, etc.), processes on the device, identifiers (unique identifiers, device IDs, and other identifiers),
                                                    device signal (Bluetooth, WLAN, etc.), device settings data (GPS, camera, photos), network and connections (mobile radio or Internet provider, language, time zone, IP address, etc.), cookie data. (Privacy policy) |</p>
                                                <p>You can object to the use of personalized advertising by making the following settings depending on your device type: On iPhone or iPad, first open "Settings", tap on "Privacy", and open "Advertising". Here you can enable "Limit Ad Tracking". If you
                                                    use devices with Android and Google Play Services, first open "Google Settings", tap on "Advertising," then open "Disable interest-related ads", then tap on "OK."</p>
                                                <p>Use of the aforementioned advertising networks and the transmission of the data mentioned is based on our legitimate interest in recouping our investments for operation of our app through advertising and the fact that your justified interests are
                                                    not overriding, Article 6(1)(f) GDPR.</p>
                                                <h2 className="bold fz24" id="7-changes-of-purpose">7. CHANGES OF PURPOSE</h2>
                                                <hr />
                                                    <p>Your personal data is only processed for purposes other than those described is only done if a legal provision allows this or you have granted your consent to the changed purpose of data processing. In the event of further processing for
                                                        purposes other than those for which the data was originally collected, we will notify you regarding those other purposes before further processing, and we will provide you with all other information relevant to this.</p>
                                                    <h2 className="bold fz24" id="8-automated-individual-decisions-or-profiling-measures">8. AUTOMATED INDIVIDUAL DECISIONS OR PROFILING MEASURES</h2>
                                                    <hr />
                                                        <p>We do not use automated processing for making decisions or profiling.</p>
                                                        <h2 className="bold fz24" id="9-disclosure-of-data">9. DISCLOSURE OF DATA</h2>
                                                        <hr />
                                                            <p>As a matter of principle, your personal data will not be disclosed to others without your explicit prior consent, except in the following circumstances:</p>
                                                            <ul>
                                                                <li><p>If needed to investigate unlawful use of our services or to prosecute a criminal offense, personal data can be forwarded to law enforcement agencies and, if appropriate, to any victims. However, this only happens if there are
                                                                    concrete clues pointing to unlawful behavior. Disclosure may likewise occur for the purpose of enforcing terms of use or other agreements. In addition, we are also required by law to disclose information to certain government
                                                                    agencies upon request, namely to law enforcement, to government agencies prosecuting misdemeanors punishable by fines, and to tax authorities.Such data are disclosed by virtue of our legitimate interest in combating abuse, for
                                                                    prosecuting criminal offenses and for securing, asserting and enforcing claims, and because your rights and your interest in the protection of your personal data do not prevail pursuant to Article 6(1)(f) GDPR. If we are legally
                                                                    required to disclose data, the disclosure occurs on the basis of Article 6(1)(c) GDPR.</p>
                                                                </li>
                                                                <li><p>To provide our services, we outsource work to outside companies under contract and to service providers ("processors"). In such cases, personal data will be transferred to such processors to allow further processing to take
                                                                    place. These processors are carefully selected and regularly reviewed by us to ensure your privacy. Processors may only use data for the purposes specified by us. Moreover, processors are contractually bound to process your data
                                                                    only in accordance with this data protection policy and with German data protection laws.Transfer of data to processors is based on Article 28(1) GDPR. Alternatively, transfer can be warranted on grounds of our legitimate interest
                                                                    in the economic and technical benefits of using specialized processors, and due to the fact that your rights and interests in protecting your personal data do not prevail under Article 6(1)(f) GDPR.</p>
                                                                </li>
                                                                <li><p>We also process data in countries outside the European Economic Area ("EEA").For the US, the European Commission resolved by decision of July 12, 2016 that under the rules for the EU-U.S. Privacy Shield an adequate degree of data
                                                                    protection exists (adequacy decision, Article 45 GDPR). Additional information – including information about certification of our service providers – is available at&nbsp;<a
                                                                        href="https://www.privacyshield.gov.In">https://www.privacyshield.gov.In</a> order to ensure protection of your personal privacy rights within these data transfers, we employ the standard agreement clauses of the EU Commission
                                                                    in accordance with Article 46(2)(c) GDPR for the development of contractual relations with third countries. These are available at&nbsp;<a
                                                                        href="http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF">http://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2010:039:0005:0018:DE:PDF</a>, alternatively you can also request these
                                                                    documents from us by using the contact options specified below.</p>
                                                                </li>
                                                                <li><p>As our business evolves, HK WONDOO CO., LIMITED may undergo organizational changes such as adoption of a new legal form or the establishment, purchase, or sale of subsidiaries and divisions or components of companies. Whenever
                                                                    such changes take place, customer information for each business unit is transferred together with the business unit. Each time personal information is disclosed to third parties as described above, we will ensure that the disclosure
                                                                    complies with this Privacy Policy and with applicable data protection laws.Transfer of data to processors is based on Article 28(1) GDPR. Alternatively, transfer can be warranted on grounds of our legitimate interest in the economic
                                                                    and technical benefits of using specialized processors, and due to the fact that your rights and interests in protecting your personal data do not prevail under Article 6(1)(f) GDPR.</p>
                                                                </li>
                                                            </ul>
                                                            <h2 className="bold fz24" id="10-deletion-of-your-data">10. DELETION OF YOUR DATA</h2>
                                                            <hr />
                                                                <p>We will delete or anonymize your personal information as soon as it is no longer needed for the purposes for which we collected or used it in accordance with the preceding paragraphs. As a rule, we store your personal data as long as
                                                                    you use the site or for the term of any applicable contract, unless the data must be kept longer for legal reasons, for criminal prosecution, or to secure, assert, or enforce legal claims.
                                                                    If data must be kept for legal reasons, it is blocked. The data is then no longer available for further use.</p>
                                                                <h2 className="bold fz24" id="11-age-limits">11. AGE LIMITS</h2>
                                                                <hr />
                                                                    <p>To use our services you must confirm full legal capacity or, in case of restricted legal capacity, obtain the consent of your legal guardian. We do not knowingly collect or ask for personal information from children. In case you
                                                                        can’t confirm full legal capacity, please do not send us any data about yourself, including name, address or email address. If we learn that we have collected personal data of a child, we will delete these as soon as possible.
                                                                        If you think that we might have data from a child, please contact us at <a href="mailto:contact@wondoo.io">contact@wondoo.io</a>.</p>
                                                                    <h2 className="bold fz24" id="12-your-rights-as-a-data-subject">12. YOUR RIGHTS AS A DATA SUBJECT</h2>
                                                                    <hr />
                                                                        <h3 className="fw400 fz20" id="121-right-to-information">12.1.&nbsp;Right to information</h3>
                                                                        <p>You are entitled at any time, upon request, to obtain information from us about your personal data within the scope of Article 15 GDPR. To request such information you may submit an application by mail or by email to the
                                                                            address given above.</p>
                                                                        <h3 className="fw400 fz20" id="122-right-to-rectification-of-inaccurate-data">12.2.&nbsp;Right to rectification of inaccurate data</h3>
                                                                        <p>You have the right to demand that we correct forthwith any inaccurate personal data concerning you. For this purpose, please use the contact addresses listed above.</p>
                                                                        <h3 className="fw400 fz20" id="123-right-to-deletion">12.3.&nbsp;Right to deletion</h3>
                                                                        <p>Under the conditions described in Article 17 GDPR, you have the right to demand the deletion of your personal data. In particular, you are entitled to demand deletion if the personal data is no longer needed for the purposes
                                                                            for which it was collected or otherwise processed, if it is no longer needed, if it was unlawfully processed, if an objection has been submitted or if deletion is mandatory under EU law or under the law of a Member State to
                                                                            which we are subject. For the period of data storage, please also see Section&nbsp;10 of this Privacy Policy. To assert the aforesaid right, please use the contact addresses listed above.</p>
                                                                        <h3 className="fw400 fz20" id="124-right-to-restriction-of-processing">12.4.&nbsp;Right to restriction of processing</h3>
                                                                        <p>You have the right to demand that we restrict processing in accordance with Article 18 GDPR. This right applies especially if we are not in agreement with the user concerning the accuracy of any personal data. The right
                                                                            persists for the time required to verify its accuracy. In addition such right applies in the following cases: if the user is entitled to demand deletion but instead demands restricted processing; if the data is no longer
                                                                            required for the purposes we are pursuing, but the user needs them in order to assert, exercise or defend legal claims; and if an objection has been raised but its successful exercise is still disputed between us and the
                                                                            user. To assert the aforesaid right, please use the contact addresses listed above.</p>
                                                                        <h3 className="fw400 fz20" id="125-right-to-data-portability">12.5.&nbsp;Right to data portability</h3>
                                                                        <p>You have the right to demand from us whatever personal information you may have given us. Pursuant to Article 20 GDPR, at your request we must provide you with such information in a structured, commonly used and
                                                                            machine-readable format. To assert the aforesaid right, please use the contact addresses listed above.</p>
                                                                        <h3 className="fw400 fz20" id="126-right-to-object">12.6 Right to object</h3>
                                                                        <p>Pursuant to Article 21 GDPR, you have the right at any time to object to the processing of your personal data on grounds related to your particular situation, including grounds under Article 6(1)(e) or (f) GDPR. We will cease
                                                                            processing your personal information unless we can demonstrate compelling and legitimate grounds for processing that outweigh your interests, rights, and freedoms, or unless the processing is done for the purpose of
                                                                            enforcing, pursuing, or defending legal claims.</p>
                                                                        <h3 className="fw400 fz20" id="127-right-to-appeal">12.7.&nbsp;Right to appeal</h3>
                                                                        <p>The competent supervisory authority to to uphold information rights in the public interest, promoting openness by public bodies and data privacy for individuals is: </p>
                                                                        <p>UK: <a href="https://ico.org.uk/global/contact-us/">https://ico.org.uk/global/contact-us/</a></p>
                                                                        <p>Other countries: Berlin Commissioner for Data Protection and Freedom of Information Friedrichstraße 219 10969 Berlin <a href="mailto:mailbox@datenschutz-berlin.de">mailbox@datenschutz-berlin.de</a>
                                                                            &nbsp;</p>
                                                                        <h2 className="bold fz24" id="13-changes-to-this-privacy-policy">13. CHANGES TO THIS PRIVACY POLICY</h2>
                                                                        <hr />
                                                                            <p>We reserve the right to update this Privacy Policy, if necessary, in compliance with the applicable data protection regulations. This way, we can adapt it to the current legal requirements and take changes in our services
                                                                                into account, e.g. when introducing new services. The most current version always applies to your visit.
                                                                                </p>
                    </div>

                </div>



        </div>
    )
}
export default Page


