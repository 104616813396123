import React, { useContext } from 'react'
import { TranslateContext } from '../context/translate'
import TermsOfServiceEn from './components/termsOfServiceEn'
import TermsOfServiceIdle from './components/termsOfServiceIdle'
import config from "../utils/config";
const Page = (props) => {
    const tranlsate=useContext(TranslateContext);
    return (
        <div>
            {config.appName == 'Idle Investor' ?  <TermsOfServiceIdle /> : <TermsOfServiceEn /> }
        </div>
    )
}
export default Page
