import React,{useState} from 'react'
import { Modal,Form, Input,message } from 'antd';
import config from "../../utils/config";
const Page = (props) => {
    let logoName=config.logoName || 'logo'

    const [form] = Form.useForm();
    const [isModalVisible,setModalVisible]=useState(false);
    const handleOk=()=>{
        form.validateFields().then(value=>{
            setModalVisible(false);
            message.success('Submitted successfully,we will process your request later ', 10)
        }).catch(err=>{
            console.log(err,'err')
        })

    }
    const handleCancel=()=>{
        setModalVisible(false)
    }

    return (
        <div className="page pad-lg pt40 pb60">
            <div className="title fz50 flex items-center ffrg">Terms of Service</div>
            <div className="wrap fz18 lh30 mt40">
                <div className="markdown-style"><h2 className="fz24 bold" id="1-general-provisions">1 General Provisions</h2>
                    <hr />
                        <p>1.1 HK WONDOO CO., LIMITED, RM 2914C, 29/F, HO KING COMMERCIAL CENTER, 2-16 FA YUEN STREET, MONGKOK, KOWLOON, HONG KONG (hereinafter, "Wondoo Tech") develops and publishes mobile applications ("Games") that can be downloaded from the App Stores of various providers.</p>
                        <p>1.2 The Games are only offered to consumers in terms of § 13 of the German Civil Code (BGB), i.e. natural persons who conclude a legal transaction for purposes which can predominantly neither be attributed to their commercial nor their self-employed professional
                            activity.</p>
                        <p>1.3 All Games made by Wondoo Tech are provided exclusively on the basis of these General Terms and Conditions. Any general terms and conditions of the user shall expressly not form part of the agreement, even if Wondoo Tech does not expressly object to their
                            applicability.</p>
                        <p>1.4 These General Terms and Conditions are displayed when downloading each Game, within the Game, on the company website  and the Game-specific websites.</p>
                        <h2 className="fz24 bold" id="2-conclusion-of-contract-term-termination">2 Conclusion of contract, term, termination</h2>
                        <hr />
                            <p>2.1 The user confirms to have full legal capacity and, in the case of restricted legal capacity, to have obtained the consent of his/her legal guardian.</p>
                            <p>2.2 The contract for the use of the Game is concluded by downloading the Game from the respective App Store. Details of this process can be found in the terms of use of the respective App Store. The download creates a contract between the user and Wondoo Tech for the
                                free use of the respective Game. The corresponding license and usage agreement on the basis of these General Terms and Conditions is concluded for an indefinite period of time and may be terminated by either party at any time, subject to Section 2.3, without
                                specifying any reasons by providing notice of four weeks.</p>
                            <p>2.3 In addition to the possibility of free use, Wondoo Tech also offers additional paid content, e.g. virtual items and power-ups (premium content). The use of the premium content is voluntary and forgoing such use does not restrict other use of the Game. The cost
                                obligation will be indicated separately in the Game. For the acquisition and use of such premium content, the provisions of Section 4 of these General Terms and Conditions as well as any separate terms and conditions of use of the App Store providers shall apply in a
                                supplementary fashion. If premium content has a defined duration (e.g. activation of certain premium functions for one month), ordinary termination (also of the contract for free use) shall be excluded during this period. To clarify, ordinary termination shall be
                                possible if the user has purchased premium content without a fixed term (e.g. one-time or permanent power-ups), even if these have effects on the entire further period of use.</p>
                            <p>2.4 It is recommended to always install the latest version of the Game. Wondoo Tech is only obligated to provide the full scope of performance and the correct functionality, even of premium content, for the current version of the Game.</p>
                            <p>2.5 In the event of ordinary termination by the user or termination by Wondoo Tech for good cause, no reimbursement of paid fees shall take place. Wondoo Tech shall be entitled to continue to charge all outstanding fees for premium content already purchased.</p>
                            <p>2.6 The user may not transfer this agreement to third parties. In the event of a breach of this provision (account transfer), Wondoo Tech shall be entitled to terminate the agreement without notice, including agreements on premium content. In such event, a prorated
                                refund shall also be excluded.</p>
                            <p>2.7 The statutory right to extraordinary termination of this Agreement without notice for good cause shall not be affected.</p>
                            <h2 className="fz24 bold" id="3-account-and-user-data">3 Account and user data</h2>
                            <hr />
                                <p>3.1 Upon conclusion of the contract and use of the Game, Wondoo Tech shall store the progress of the Game and any additional paid content. For more information, please refer to Wondoo Tech’ privacy policy.</p>
                                <p>3.2 The user must select secure access credentials and not make such data available to third parties. Wondoo Tech is not responsible for any damage resulting from the breach of this duty. In the event of suspected abuse, Wondoo Tech shall be entitled to temporarily
                                    block access to the Game in question.</p>
                                <p>3.3 If the user can select a username in the Game, this must not violate applicable laws and/or these General Terms and Conditions. Wondoo Tech shall be entitled to change the name in the event of a breach of this provision, either at the instigation of a third
                                    party or on its own initiative. No separate justification for the name change shall be necessary. The user has no claim to a specific name. Other rights of Wondoo Tech in the event of a breach of this provision shall not be affected.</p>
                                <h2 className="fz24 bold" id="4-premium-content">4 Premium content</h2>
                                <hr />
                                    <p>4.1 The contract for additional premium content shall only be concluded if the user clicks on the "Pay" or "Order for a fee" button in the shop area of the Game (exact labelling may vary for in-app purchases depending on the App Store). The contract shall be
                                        accepted by Wondoo Tech (or on its behalf by the respective App Store provider) by sending an order confirmation via message or email, or by actually providing the respective premium content. The fee shall be payable in advance upon acquisition of premium
                                        content.</p>
                                    <p>4.2 A refund after the purchase of premium content can only be made within the scope of statutory consumer rights or the statutory right of revocation with due regard to the respective elements of exclusion.</p>
                                    <p>4.3 The prices for premium content as displayed via the respective App Stores and transmitted in the order confirmation for the respective order shall apply in each case. These can vary according to currency and country. The prices include any applicable value
                                        added tax. Payments will be processed via the respective App Stores. The terms of use and payment of the App Stores shall apply in this regard, supplemented by these General Terms and Conditions. In the event of contradictions between the general terms and
                                        conditions of the App Stores and these General Terms and Conditions, the terms of use and payment of the App Stores shall take precedence.</p>
                                    <p>4.4 If currencies are simulated in the Games (hereinafter, "In-app Cash"), these represent premium content or units of measurement for the scope of the license to use the Game in question, and not real money. If such In-app Cash can be earned both through Game
                                        play and as premium content, the units purchased in return for payment will always be used first when such In-app Cash are used. In-app Cash may only be converted back into real money if the operation of the Game as a whole is permanently discontinued. In such
                                        an event, the fee paid for In-app Cash shall be refunded insofar as the corresponding In-app Cash have not yet been used to activate Game content.</p>
                                    <p>4.5 The user shall be responsible for cancellations or chargebacks which the user itself has caused, e.g. due to insufficient account coverage. The user shall normally bear the costs arising therefrom. Wondoo Tech shall be entitled to collect these costs
                                        together with the original fee from the user’s means of payment. The user shall reserve the right to prove that any damage did not occur or did not occur to the value of the requested amount.</p>
                                    <p>4.6 If the user defaults on payment, Wondoo Tech may, irrespective of the continued obligation to pay, block access to the Game and demand the default interest stipulated by law.</p>
                                    <p>4.7 Wondoo Tech may offer new premium content, change existing premium content and discontinue premium content. Permanent premium content already purchased (virtual items), which are removed from the Game, may in this case be used within a transitional period
                                        or exchanged for new premium content. Further claims of the user shall be excluded.</p>
                                    <p>4.8 For purposes of clarification: All claims of the user with respect to the premium content, particularly with respect to copyrights, warranty for defects and damage compensation, shall be asserted against Wondoo Tech (and not against the App Stores).</p>
                                    <h2 className="fz24 bold" id="5-subscriptions">5 Subscriptions</h2>
                                    <hr />
                                        <p>5.1 Certain features of the Games might be available through a subscription. Subscriptions may be available at different fees chargeable for a set period specified in the Games (“Subscription Period”). If you order a subscription, you must ensure that all
                                            the information you submit is true and accurate and that you have appropriate parental or guardian consent if you are under 18 years of age.</p>
                                        <p>5.2 The user can manage subscriptions and turn off auto-renewals in the respective device’s account settings.</p>
                                        <p>5.3 Wondoo Tech reserves the right to alter the amount and/or terms of the subscription fees at any time. Additional information to price changes can be found in the terms of the respective platform.</p>
                                        <p>5.4 Additional information on payment and use of subscriptions can be found in the terms of the respective platform:</p>

                                        <h3 className="fz20 fw400" id="monthly-subscription">Monthly Subscription</h3>
                                        <p>5.5 If the user chooses to purchase the monthly subscription offer, the displayed monthly amount will be charged to his/her Apple or Google Play account after confirming the purchase. It will auto-renew unless canceled at least 24 hours before the end of
                                            each 30-day period (“Monthly Subscription Period”).</p>
                                        <h3 className="fz20 fw400" id="weekly-subscription">Weekly Subscription</h3>
                                        <p>5.6 If the user chooses to purchase the weekly subscription offer, the displayed weekly amount will be charged to his/her Apple or Google Play account after confirming the purchase. It will auto-renew unless canceled at least 24 hours before the end of each
                                            7-day period (“Weekly Subscription Period”).</p>
                                        <h3 className="fz20 fw400" id="free-trial">Free Trial</h3>
                                        <p>5.7 For selected subscription offers, a trial period may be included. In this case, the exclusive contents of the subscription may be used free of charge for a specific period specified in the offer ("Free Trial Period"). This will automatically be
                                            converted into a paid subscription unless canceled at least 24 hours before the end of the Free Trial Period.</p>
                                        <p>5.8 A Free Trial Period can only be claimed once by each user. If a user has already used the Free Trial Period, the subscription fee will be charged directly after confirmation of the purchase of the subscription.</p>
                                        <h2 className="fz24 bold" id="6-instructions-on-revocation">6 Instructions on revocation</h2>
                                        <hr />
                                            <p>With regard to contracts in return for payment, consumers (§ 13 of the German Civil Code) have a right of revocation in accordance with the following provisions:</p>
                                            <h3 className="fz20 fw400" id="revocation-right">Revocation right</h3>
                                            <p>You have the right to revoke this contract within 14 days without specifying any reasons.</p>
                                            <p>The revocation period is 14 days from the closing date of the contract.</p>
                                            <p>In order to exercise your revocation right, you must contact us (Wondoo&nbsp;Technology&nbsp;Co.,&nbsp;Ltd., RM 2914C, 29/F, HO KING COMMERCIAL CENTER, 2-16 FA YUEN STREET, MONGKOK, KOWLOON, HONG KONG; e-mail: <a
                                                href="mailto:contact@wondoo.io">contact@wondoo.io</a>;) by means of a clear declaration (e.g. a letter by post or e-mail) of your decision to revoke this contract. You can use the model revocation form for this purpose, though this is not mandatory.
                                            </p>
                                            <p>In order to comply with the revocation period, it is sufficient that you send the notice of the exercise of the right of revocation before the expiry of the revocation period.</p>
                                            <h3 className="fz20 fw400" id="consequence-of-revocation">Consequence of revocation</h3>
                                            <p>If you revoke this Agreement, Wondoo&nbsp;Technology&nbsp;Co.,&nbsp;Ltd. shall immediately, i.e. no later than fourteen days from the date on which we receive notice of the revocation of this Agreement, repay all payments received from you, including
                                                delivery costs (excluding additional costs resulting from your choosing a different type of delivery from the cheapest standard delivery offered by us). For this refund, we will use the same means of payment that you used for the original transaction,
                                                unless expressly agreed otherwise with you; in no event shall you be charged for this refund.</p>
                                            <p>End of instructions on revocation.</p>
                                            <h3 className="fz20 fw400" id="special-reference-to-the-premature-expiry-of-the-revocation-right">Special reference to the premature expiry of the revocation right:</h3>
                                            <p>Your right of revocation shall expire prematurely if we have begun to execute the contract before the expiry of the revocation period, after you as the user have given your express consent to this and have confirmed your awareness that you will lose
                                                your revocation right as a result of the consent at the start of the execution of the contract.</p>
                                            <h3 className="fz20 fw400" id="revocation-form">Revocation form</h3>
                                            <p>(If you want to revoke the contract, please fill out this form and send it back.)</p>
                                            <ul>
                                                <li><p>To Wondoo&nbsp;Technology&nbsp;Co.,&nbsp;Ltd., RM 2914C, 29/F, HO KING COMMERCIAL CENTER, 2-16 FA YUEN STREET, MONGKOK, KOWLOON, HONG KONG; e-mail: <a href="mailto:contact@wondoo.io">contact@wondoo.io</a>:</p>
                                                </li>
                                                <li><p>I/we <code>(*)</code> hereby revoke the contract concluded by me/us <code>(*)</code> for the purchase of the the following goods /the provision of the following service <code>(*)</code></p>
                                                </li>
                                                <li><p>ordered on <code>(*)</code>/received on <code>(*)</code></p>
                                                </li>
                                                <li><p>Name(s) of consumer(s) - Address of consumer(s)</p>
                                                </li>
                                                <li><p>Signature of consumer(s) (only for hard copy form)</p>
                                                </li>
                                                <li><p>Date</p>
                                                </li>
                                            </ul>
                                            <hr />
                                                <p>(*) Delete if appropriate.</p>
                                                <h2 className="fz24 bold" id="7-user-obligations">7 User obligations</h2>
                                                <hr />
                                                    <p>7.1 When using the Games, the user may not use any other software or other routines that could interfere with the systems of Wondoo Tech or other users. This includes actions that could lead to an unreasonable overloading of the systems of
                                                        Wondoo Tech. The use of special software, particularly for systematic or automatic control of Games, is prohibited.</p>
                                                    <p>7.2 The user may not use any software or exploit any programming errors that lead or could lead to obtaining premium content in the Games or other unintended benefits for the user without having concluded a premium agreement, the user thus
                                                        becoming obliged to pay the fee for such premium content. In the event of unjustified acquisition of premium content, Wondoo Tech may delete or deactivate the unjustified premium content or other benefits. For the purposes of clarification: The
                                                        above shall not apply if premium content can be acquired as a reward for certain Game actions.</p>
                                                    <p>7.3 In connection with the services of Wondoo Tech, the user may not use any software that enables “data mining” or otherwise collects information in connection with the Games.</p>
                                                    <p>7.4 The user may not use, buy for real money (except through the App Stores), sell, trade, or attempt to use Premium Content outside the Game.</p>
                                                    <p>7.5 When using any communication functions within the Games, the user must comply with all applicable laws and must not infringe the rights of third parties. In addition, certain content is undesirable in the Games because it contradicts the
                                                        character of the Games as family entertainment media. In concrete terms, this means that users may not:</p>
                                                    <ul>
                                                        <li><p>use, post or publish any harassing, threatening, disturbing, insulting or defamatory, discriminatory, political, immoral, pornographic, morally reprehensible, offensive, violent, sexist, extreme right-wing or left-wing content or content
                                                            that glorifies violence or violates laws, particularly laws for the protection of minors, or link to corresponding material on a third party website or advertise, offer or distribute such content or products that violates laws, particularly
                                                            laws for the protection of minors;</p>
                                                        </li>
                                                        <li><p>and publish, duplicate, make publicly accessible or disseminate content protected for the benefit of third parties without a corresponding license, in particular infringe upon industrial property rights, advertise, offer or distribute
                                                            goods or services, commit or promote anti-competitive acts, request other users to provide personal data for commercial or illegal purposes or to provide access data, organize or advertise commercial activities and sales of third parties,
                                                            duplicate an image of another person or make it publicly accessible without the written consent of the person concerned, or publish personal data and confidential data without being entitled to do so.</p>
                                                        </li>
                                                    </ul>
                                                    <p>7.6 Wondoo Tech shall be entitled to delete content that violates applicable law and/or these General Terms and Conditions.</p>
                                                    <p>7.7 The user may only use the Games for private purposes, unless Wondoo Tech has given its express prior written consent to any other use in individual cases.</p>
                                                    <p>7.8 Any breach of these General Terms and Conditions may lead to termination for good cause. In lieu of such termination, Wondoo Tech may, at its sole discretion, announce measures or take sanctioning measures of any kind to enforce the
                                                        provisions of these General Terms and Conditions. In particular, this shall include declaring a temporary or permanent virtual ban from the systems, warning the user of their temporary or permanent exclusion of the user from individual Game
                                                        functions.</p>
                                                    <p>7.9 Users shall be obligated to regularly back up all their data on the devices on which they use the Games in a manner appropriate to the risk.</p>
                                                    <h2 className="fz24 bold" id="8-rights-indemnity">8 Rights, indemnity</h2>
                                                    <hr />
                                                        <p>8.1 Wondoo Tech grants the user a non-exclusive, non-transferable and non-sublicensable right of use for private use with respect to the respective Game for the duration of the contract. This shall include multiple downloads to the device(s)
                                                            linked to the respective account in the respective App Store. Furthermore, the Game may not be reproduced, distributed, publicly performed, broadcast or made publicly accessible on the Internet or via a network, edited or stored on data
                                                            carriers. It may also not be used or exploited commercially. In addition, decompilation, disassembly and reverse engineering shall be prohibited, unless expressly permitted by law.</p>
                                                        <p>8.2 If the user sends Wondoo Tech feedback, suggestions and/or ideas (collectively referred to as “Feedback”) regarding the Game or possible enhancements or new developments of Games, Wondoo Tech may incorporate such Feedback into its
                                                            Products and/or incorporate such Feedback into the further development of its Products and use such Feedback as part of the Products, or otherwise publish, edit, use and exploit such Feedback. The user grants Wondoo Tech, free of charge,
                                                            the permanent, non-exclusive, spatially unlimited, royalty-free, transferable and sub-licensable right to reproduce, edit, distribute, distribute and make publicly available (in whole and/or in part, and in each case also with respect to
                                                            adaptations thereof) and otherwise to exploit all Feedback provided by the user. Wondoo Tech shall not be obliged to respond to Feedback and/or to use or exploit it. The user waives all possible copyrights in connection with the possible
                                                            use and exploitation of the Feedback.</p>
                                                        <p>8.3 The user shall indemnify Wondoo Tech against all claims, including damage compensation claims, asserted by third parties due to an infringement of their rights by the user’s conduct or the content or data posted. The user shall also be
                                                            obliged to reimburse the reasonable costs incurred as a result thereof, particularly the costs arising from any legal defense that may be required (including lawyers’ fees customary for the market, which shall not be limited to the
                                                            statutory fees). This shall not apply if the user is not responsible for the infringement. All further rights and claims of Wondoo Tech shall not be affected.</p>
                                                        <h2 className="fz24 bold" id="9-warranty-liability">9 Warranty, liability</h2>
                                                        <hr />
                                                            <p>9.1 Insofar as Wondoo Tech provides services free of charge, no warranty claims shall exist and Wondoo Tech shall only be liable in accordance with the provisions of law for intentional and gross negligence; Wondoo Tech shall only be
                                                                liable for defects if it has fraudulently concealed them.</p>
                                                            <p>9.2 If services are rendered in return for payment, the warranty claims of the user shall be governed by the provisions of law, unless stipulated otherwise in this Section 9.2.</p>
                                                            <p>9.2.1 A warranty shall only be given for the current program version.</p>
                                                            <p>9.2.2 The statutory liability of Wondoo Tech for damage compensation and reimbursement of futile expenses shall be unrestricted only in the event of intentional action or gross negligence, in the event of negligent or intentional injury
                                                                to life, limb or health, due to the assumption of a guarantee (which must be expressly designated as such in order to constitute a guarantee in the legal sense), and due to mandatory liability under the Product Liability Act. “Material
                                                                contractual duties” shall mean duties whose fulfilment initially enables the achievement of the contractual purpose and on whose compliance the parties may therefore normally rely.</p>
                                                            <p>9.2.3 In events other than those referred to in Section 9.2.2, Wondoo Tech shall only be liable for minor negligence in the event of a breach of a material contractual duty. Otherwise, Wondoo Tech shall not be liable for minor
                                                                negligence.</p>
                                                            <p>9.2.4 The damage compensation for the minor negligent breach of material contractual duties in accordance with Section 9.2.3 shall be limited to the foreseeable damage typical in contracts.</p>
                                                            <p>9.3 To the extent Wondoo Tech is liable for data loss on the merits in accordance with the foregoing, the amount of such liability shall also be limited to the costs that would have been necessary to restore the data if the user had made
                                                                reasonable regular data backups.</p>
                                                            <h2 className="fz24 bold" id="10-modifications-of-these-general-terms-and-conditions">10 Modifications of these General Terms and Conditions</h2>
                                                            <hr />
                                                                <p>10.1 Wondoo Tech reserves the right to amend these General Terms and Conditions and other terms and conditions with effect for the future in accordance with this Section 10.</p>
                                                                <p>10.2 Wondoo Tech shall notify you in writing (e.g. by message within the Game or by e-mail) at least four weeks in advance of any changes for necessary reasons, e.g. for reasons relating to the expansion of services or changes to the
                                                                    Games, amendments to laws or legal rulings or other equivalent reasons that do not affect the main performance duties of the parties. These General Terms and Conditions shall then be deemed approved if the user does not object in
                                                                    writing or in text form within four weeks of receiving this information. The time limit for objection and the consequences of silence shall be indicated separately in the notice.</p>
                                                                <p>10.3 Changes other than those mentioned in Section 10.2 shall require the user’s consent, which can be requested at the start of the next Game.</p>
                                                                <p>10.4 If there is an objection in accordance with Section 10.2 or no consent given in accordance with Section 10.3, the user shall retain the right to use the current version of the Game. However, Wondoo Tech may make the use of an
                                                                    extended version of the Game subject to approval of the modification of these General Terms and Conditions.</p>
                                                                <h2 className="fz24 bold" id="11-applicable-law-jurisdiction">11 Applicable law, jurisdiction</h2>
                                                                <hr />
                                                                    <p>11.1 German law shall apply to the exclusion of the UN Convention on Contracts for the International Sale of Goods. If the user is resident in another member state of the EU, this choice of law shall not result in the mandatory
                                                                        consumer protection regulations of his or her home country being rescinde</p>
                                                                    <p>11.2 For disputes with the user, the statutory place of jurisdiction shall apply, provided the user has a general place of jurisdiction in Germany. If the user has moved his or her domicile or habitual place of residence from
                                                                        Germany after concluding the contract or if his or her domicile or habitual place of residence is not known at the time the action is filed, Berlin shall be the place of jurisdiction.</p>
                </div>
            </div>



        </div>
    )
}
export default Page


